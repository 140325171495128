<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmBooking')" data-vv-scope="frmBooking" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-12">
          <div class="card card-fullheight">
            <div class="card-body">
              <h5 class="box-title mb-3 mt-2">{{ $t('booking.new-edit_booking') }}</h5>
              <hr>

              <!-- Operação, Origem e Destino -->
              <div class="row custom-row">
                <!--  Operação  -->
                <div class="col-lg-3 col-md-6 form-group px-2 mt-2">
                  <dual-switch
                    v-bind:options="operationOptions"
                    v-bind:selectedItem="booking.tipoOperacao"
                    v-on:set-option="setOperation"
                    :disabled="processInformed"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.origem') || errors.first('booking.destino')"
                      class="royalc-error-field">&nbsp;</small>
                  </transition>
                </div>

                <!--  Origem  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.origin')  }}</label>
                  <v-select class="grey-field" name="booking.origem" label="nome" :clearable="false" :searchable="true"
                    v-model="booking.origem" :options="originList" @search="originFetchOptions"
                    :filter-by="originDestinationFilter" v-validate="{ required: true }" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma origem
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma origem encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.origem')" class="royalc-error-field">{{ errors.first('booking.origem') }}</small>
                  </transition>
                </div>

                <!--  Destino  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.destination')  }}</label>
                  <v-select class="grey-field" name="booking.destino" label="nome"  :clearable="false" :searchable="true"
                    v-model="booking.destino"  :options="destinationList" @search="destinationFetchOptions"
                    :filter-by="originDestinationFilter" v-validate="{ required: true }" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um destino
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum destino encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.destino')" class="royalc-error-field">{{ errors.first('booking.destino') }}</small>
                  </transition>
                </div>

                <!--  Mercadoria  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Mercadoria</label>
                  <v-select name="booking.mercadoria" class="grey-field" label="nome" :clearable="false" :searchable="true"
                    :filter-by="nomeFilterBy"
                    v-model="booking.mercadoria"  :options="commoditieList" @search="commoditieFetchOptions"
                    v-validate="{ required: true }" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma mercadoria
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma mercadoria encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.mercadoria')" class="royalc-error-field">{{ errors.first('booking.mercadoria') }}</small>
                  </transition>
                </div>

                <!--  Equipamentos  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.equipments')  }}</label>
                  <search-bar-quantity
                    name="booking.equipamentos" field-name="descricao"
                    v-model="booking.equipamentos" :options="equipmentList" v-validate="{ required: true }" :disabled="processInformed"/>
                  <transition name="slide" mode="">
                    <small v-if="equipamentsError" class="royalc-error-field">Campo é obrigatório</small>
                  </transition>
                </div>

                <!--  Solicitante  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Solicitante</label>
                  <v-select class="grey-field" name="booking.solicitante" label="nome" :clearable="false"
                    :searchable="true" v-model="booking.solicitante" :options="employeeList"
                    @search="employeeFetchOptions" v-validate="{ required: true }">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um colaborador
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum colaborador encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.solicitante')" class="royalc-error-field">{{ errors.first('booking.solicitante') }}</small>
                  </transition>
                </div>

                <!--  Vendedor  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Vendedor</label>
                  <v-select class="grey-field" name="booking.vendedor" label="nome" :clearable="true"
                    :searchable="true" v-model="booking.vendedor" :options="sellerList"
                    @search="sellerFetchOptions" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um vendedor
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum vendedor encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.vendedor')" class="royalc-error-field">{{ errors.first('booking.vendedor') }}</small>
                  </transition>
                </div>

                <!--  Situação  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label for="booking.situacao" class="small grey-label">Situação</label>
                  <v-select class="grey-field" name="booking.situacao" label="label" :clearable="false" :searchable="false"
                    v-model="booking.situacao" :options="statusList" v-validate="{ required: true }" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.situacao')" class="royalc-error-field">
                      {{ errors.first('booking.situacao') }}</small>
                  </transition>
                </div>

                <!--  Armador  -->
                <div class="col-lg-6 col-md-6 form-group px-2">
                  <label class="small grey-label">Armador</label>
                  <v-select class="grey-field" name="booking.armador" label="name" :clearable="true"
                    :searchable="true" v-model="booking.armador" :options="shipOwnerList"
                    @search="shipOwnerFetchOptions" @input="updateVessel" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um armador
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum armador encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.armador')" class="royalc-error-field">{{ errors.first('booking.armador') }}</small>
                  </transition>
                </div>

                <!--  Navio  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Navio</label>
                  <v-select class="grey-field" name="booking.navio" label="name" :clearable="true"
                    :searchable="true" v-model="booking.navio" :options="vesselList"
                    @search="vesselFetchOptions" :disabled="!booking.armador || processInformed">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um navio
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum navio encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.navio')" class="royalc-error-field">{{ errors.first('booking.navio') }}</small>
                  </transition>
                </div>

                <!--  Número do Booking  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Referência (Booking)</label>
                  <input v-model="booking.numero" type="text" class="md-form-control">
                </div>

                <!--  Cliente  -->
                <div class="col-lg-6 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.customer')  }}</label>
                  <v-select class="grey-field" name="booking.cliente" label="name" :clearable="true"
                    :searchable="true" v-model="booking.cliente" :options="customerList"
                    @search="customerFetchOptions" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um cliente
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum cliente encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.cliente')" class="royalc-error-field">{{ errors.first('booking.cliente') }}</small>
                  </transition>
                </div>

                <!--  Processo  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label">Processo</label>
                  <v-select class="grey-field" name="booking.processo" label="name" :clearable="true"
                    :searchable="true" v-model="booking.processo" :options="processList" :filter-by="filterBy"
                    @search="processFetchOptions" @input="updateProcessFields">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um processo
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum processo encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.processo')" class="royalc-error-field">{{ errors.first('booking.processo') }}</small>
                  </transition>
                </div>

                <!--  Contrato  -->
                <div class="col-lg-6 col-md-6 form-group px-2">
                  <label class="small grey-label">Contrato de Frete</label>
                  <v-select class="grey-field" name="value.contrato" label="nome" :clearable="false" :searchable="true"
                    v-model="booking.contrato" :options="contractList" @search="contractFetchOptions"
                    :filter-by="filterBy" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um contrato
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma contrato encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('value.contrato')" class="royalc-error-field">{{ errors.first('value.contrato') }}</small>
                  </transition>
                </div>

                <!--  Semana  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label mb-0">Semana</label>
                  <custom-input
                    v-model="booking.semana"
                    name="booking.semana"
                    type="number"
                    rootClassName="md-form my-0 py-0"
                    inputClassName="md-form-control text-center"
                    :error="submitted ? errors.first('booking.semana') : ''"
                    @blur="onLostFocus()">
                  </custom-input>
                </div>

                <!--  Multa  -->
                <div class="col-lg-3 col-md-6 form-group px-2 ">
                  <label class="small grey-label mb-0">Multa</label>
                  <label class="ui-switch no-margin small switch-solid switch-warning pl-2">
                    <input id="possuiMulta" value="1" type="checkbox"
                      ref="possuiMulta" v-model="booking.possuiMulta"
                      :checked="booking.possuiMulta" @change="clearCharge()"><span> </span>
                  </label>
                  <input type="number" min="0" step=".01" class="md-form-control text-center"
                    v-model="booking.valorMulta"
                    :disabled="!booking.possuiMulta"
                    :error="submitted ? errors.first('booking.valorMulta') : ''">
                </div>

                <!--  ETD  -->
                <div class="col-lg-2 col-md-6 form-group px-2">
                    <custom-input-date
                      v-model="booking.etd"
                      name="booking.etd"
                      label="ETD"
                      type="text"
                      v-on:input="booking.etd = $event"
                      v-validate="{ required: false }"
                      :error="errors.first('booking.etd')">
                    </custom-input-date>
                </div>

                <!--  Deadline  -->
                <div class="col-lg-2 col-md-6 form-group px-2">
                  <custom-input-date
                    v-model="booking.ddl"
                    name="booking.ddl"
                    label="Deadline"
                    type="text"
                    v-on:input="booking.ddl = $event"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.ddl')">
                  </custom-input-date>
                </div>

                <!--  Data de solicitação  -->
                <div class="col-lg-2 col-md-6 form-group px-2">
                  <custom-input-date
                    v-model="booking.dataSolicitacao"
                    name="booking.dataSolicitacao"
                    label="Solicitação"
                    type="text"
                    v-on:input="booking.dataSolicitacao = $event"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.dataSolicitacao')">
                  </custom-input-date>
                </div>

                <!-- Observação -->
                <div class="col-md-12 mt-4">
                  <label>Observação</label>
                  <custom-textarea
                    v-model="booking.observacao"
                    name="booking.observacao"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.observacao')">
                  </custom-textarea>
                </div>
              </div>

              <hr>

              <div class="row custom-row">
                <!-- Submit -->
                <div class="col-12">
                  <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
                    Salvar
                  </button>
                  <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
                    Cancelar
                  </button>
                  <router-link v-show="!isEdit"
                    :to="{name: 'BookingContractNew'}" class="btn btn-link btn-sm mt-1 pull-left">
                    Contratual
                  </router-link>
                  <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
                    Console
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import DualSwitch from '../../components/Forms/DualSwitch.vue'
import SearchBarQuantity from '../Proposal/SearchBarQuantity.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomInputDate from '@/components/Forms/CustomInputDate.vue'
// import ArmadorField from '@/components/Forms/ArmadorField'
import CustomInput from '@/components/Forms/CustomInput.vue'
// import StatusSelector from './StatusSelector.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import EmployeeService from '@/services/EmployeeService'
import BookingService from '@/services/BookingService'
import OriginDestinationService from '@/services/OriginDestinationService'
import CommoditieService from '@/services/CommoditieService'
import ProposalService from '@/services/ProposalService'
import EquipmentService from '@/services/EquipmentService'
import ProcessService from '@/services/Process/ProcessService'

export default {
  name: 'BookingForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_booking') + ' - %s'
    }
  },
  data () {
    return {
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/Booking',
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      timer: null,
      delay: 400,
      //  Selected Values (Form)
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: {
          id: '0',
          label: 'Solicitar'
        },
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0,
        contrato: null,
        semana: null
      },
      // List of options to select on select input
      operationOptions: [
        {
          id: 2,
          name: 'IMPORT'
        },
        {
          id: 1,
          name: 'EXPORT'
        }
      ],
      bookingList: [],
      statusList: [],
      employeeList: [],
      sellerList: [],
      originList: [],
      customerList: [],
      destinationList: [],
      commoditieList: [],
      shipOwnerList: [],
      vesselList: [],
      travelList: [],
      contractList: [],
      equipmentList: [],
      processList: []
    }
  },
  components: {
    Loading,
    vSelect,
    SearchBarQuantity,
    CustomTextarea,
    CustomInputDate,
    // ArmadorField,
    CustomInput,
    // StatusSelector,
    DualSwitch
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'BookingEdit'
    let bookingId = this.$route.params.id

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': true
    }

    this.getStatusOptions()

    // Load basilares data
    CommoditieService.getCommodities(filters).then(res => {
      _this.commoditieList = res.data.data
      filters.per_page = 100
      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        BookingService.getShipOwners(filters).then(res => {
          _this.shipOwnerList = res.data.data
        })
      })
    })

    EmployeeService.getOptions(filters).then(res => {
      _this.employeeList = res.data.data
    })

    EmployeeService.getSellers().then(res => {
      _this.sellerList = res.data.data
    })

    _this.equipmentLoadOptions().then(res => {
      _this.equipmentList = res
    })

    if (bookingId) {
      BookingService.getBooking(bookingId).then(res => {
        let data = res.data.data
        let edit = this.booking

        edit.id = bookingId

        if (this.$util.isset(data.id_porto_origem)) {
          edit.origem = {
            id: data.id_porto_origem.id,
            nome: data.id_porto_origem.nome,
            pais: data.id_porto_origem.id_pais.nome,
            sigla: data.id_porto_origem.sigla
          }
        }

        if (this.$util.isset(data.id_porto_destino)) {
          edit.destino = {
            id: data.id_porto_destino.id,
            nome: data.id_porto_destino.nome,
            pais: data.id_porto_destino.id_pais.nome,
            sigla: data.id_porto_destino.sigla
          }
        }

        if (this.$util.isset(data.id_mercadoria)) {
          edit.mercadoria = {
            id: data.id_mercadoria.id,
            nome: data.id_mercadoria.nome_portal
          }
        }

        edit.situacao = this.statusList.find(x => parseInt(x.id) === data.situacao)

        if (this.$util.isset(data.idcompanhia_transporte)) {
          edit.armador = {
            id: data.idcompanhia_transporte.id,
            id_table_origin: data.idcompanhia_transporte.id_table_origin,
            name: data.idcompanhia_transporte.nome
          }
        }

        if (this.$util.isset(data.idcontrato_frete)) {
          edit.contrato = {
            id: data.idcontrato_frete.idcontrato_frete,
            id_table_origin: data.idcontrato_frete.id_table_origin,
            nome: data.idcontrato_frete.numero_contrato
          }
        }

        edit.semana = data.semana

        if (this.$util.isset(data.id_navio)) {
          edit.navio = {
            id: data.id_navio.id,
            id_table_origin: data.id_navio.id_table_origin,
            name: data.id_navio.nome
          }
        }

        edit.numero = data.numero

        if (this.$util.isset(data.id_solicitante)) {
          edit.solicitante = {
            id: data.id_solicitante.id,
            id_table_origin: data.id_solicitante.id_table_origin,
            nome: data.id_solicitante.id_pessoa.nome
          }
        }

        if (this.$util.isset(data.id_vendedor)) {
          edit.vendedor = {
            id: data.id_vendedor.id,
            id_table_origin: data.id_vendedor.id_table_origin,
            nome: data.id_vendedor.id_pessoa.nome
          }
        }

        if (this.$util.isset(data.id_cliente)) {
          edit.cliente = {
            id: data.id_cliente.id,
            id_table_origin: data.id_cliente.id_table_origin,
            name: data.id_cliente.idpessoa.nome
          }
        }

        if (this.$util.isset(data.id_logistica_house)) {
          edit.processo = {
            id: data.id_logistica_house.id,
            id_table_origin: data.id_logistica_house.id_table_origin,
            name: data.id_logistica_house.numero_processo
          }
        }

        if (this.$util.isset(data.dead_line)) {
          edit.ddl = data.dead_line
        }

        edit.equipamentos = data.equipamentos.map(x => {
          return {
            id: x.idequipamento_maritimo.id,
            descricao: x.idequipamento_maritimo.descricao,
            description: x.idequipamento_maritimo.descricao,
            quantity: x.quantidade
          }
        })

        edit.etd = data.etd
        edit.dataSolicitacao = data.data_solicitacao
        edit.possuiMulta = data.possui_multa
        edit.valorMulta = parseFloat(data.valor_multa)
        edit.observacao = data.observacao

        this.booking = edit
      })
    }

    _this.isLoading = false
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectEmployee (val) {
      this.Booking.employee = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Booking: object.id } }).href
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search,
        'includeAlan': true
      }

      EmployeeService.getOptions(filters).then(res => {
        me.employeeList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    sellerFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      EmployeeService.getSellers(filters).then(res => {
        me.sellerList = res.data.data
        loading(false)
      })
    },
    nomeFilterBy (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    customerFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        loading(false)
      })
    },
    originDestinationFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1 || this.$util.slugify(option.sigla).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    /**
     * Triggered when the origin search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'operation': _this.booking.tipoOperacao.id,
        'searchTerm': search
      }

      OriginDestinationService.getOriginsByOperation(filters).then(res => {
        _this.originList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the destination search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    destinationFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'operation': _this.booking.tipoOperacao.id,
        'searchTerm': search
      }

      OriginDestinationService.getDestinationsByOperation(filters).then(res => {
        _this.destinationList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditieFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CommoditieService.getCommodities(filters).then(res => {
        _this.commoditieList = res.data.data
        loading(false)
      })
    },
    shipOwnerFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      BookingService.getShipOwners(filters).then(res => {
        _this.shipOwnerList = res.data.data
        loading(false)
      })
    },
    updateVessel () {
      this.vesselList = []
      this.booking.navio = null
    },
    /**
     * Triggered when the vessel search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    vesselFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      BookingService.getVesselsByShipOwner(_this.booking.armador.id, filters).then(res => {
        _this.vesselList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the process search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    processFetchOptions (search, loading) {
      if (search.length > 0) {
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'operation': [this.booking.tipoOperacao.id],
            'modal': [2] // Extranet >> Enums/Modal::MARITIMO
          }

          ProcessService.getOptions(filters).then(res => {
            this.processList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    getStatusOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      BookingService.getStatusOptions(false).then(res => {
        _this.statusList = Object.entries(res.data.data).map((key) => {
          return {
            id: key[0],
            label: key[1]
          }
        })
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    contractFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search
          }

          if (this.booking.armador) {
            filters['pessoacontrato'] = this.booking.armador.id
          }

          BookingService.getContractOptions(filters).then(res => {
            _this.contractList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frmBooking').then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
            BookingService.putBooking(this.booking.id, this.booking).then(response => {
              _this.$router.push({ name: 'BookingIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            BookingService.postNewBooking(this.booking).then(response => {
              _this.$router.push({ name: 'BookingIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    },
    setOperation (obj) {
      if (this.booking.tipoOperacao.id !== obj.id) {
        this.booking.origem = {
          id: null,
          nome: null
        }
        this.booking.destino = {
          id: null,
          nome: null
        }
      }
      this.booking.tipoOperacao = obj
    },
    equipmentLoadOptions () {
      // let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': 2, // marítimo
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            // for (let sI in _this.filterData.equipments) {
            //   let selected = _this.filterData.equipments[sI]

            //   if (selected.id === item.id) {
            //     item.quantity = selected.quantity
            //     break
            //   }
            // }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    onShow () {
      // console.log('Booking: ', this.booking)
    },
    clearCharge () {
      if (!this.booking.possuiMulta) {
        this.booking.valorMulta = null
      }
    },
    onLostFocus () {
      if (this.booking.semana === '') {
        return
      }

      if (this.booking.semana > 52) {
        this.booking.semana = 52
      }

      if (this.booking.semana < 1) {
        this.booking.semana = 1
      }
    },
    updateProcessFields () {
      if (!this.booking.processo) {
        return
      }

      this.loadSelectedProcessEquipment()

      this.booking.cliente = {
        id: this.booking.processo.customer_id,
        cnpj: this.booking.processo.customer_cnpj,
        name: this.booking.processo.customer_name
      }

      this.booking.vendedor = {
        id: this.booking.processo.seller_id,
        nome: this.booking.processo.seller_name
      }

      this.booking.tipoOperacao = this.operationOptions.find(x => x.id === this.booking.processo.operation)

      this.booking.origem = {
        id: this.booking.processo.origin_id,
        nome: this.booking.processo.origin_name
      }

      this.booking.destino = {
        id: this.booking.processo.destin_id,
        nome: this.booking.processo.destin_name
      }

      this.booking.mercadoria = {
        id: this.booking.processo.commodity_id,
        nome: this.booking.processo.commodity_name
      }

      this.booking.armador = {
        id: this.booking.processo.shipper_id,
        name: this.booking.processo.shipper_name
      }

      this.booking.navio = {
        id: this.booking.processo.ship_id,
        name: this.booking.processo.ship_name
      }

      this.booking.numero = this.booking.processo.reference

      this.booking.contrato = {
        id: this.booking.processo.contract_id,
        nome: this.booking.processo.contract_name
      }
    },
    loadSelectedProcessEquipment () {
      this.isLoading = true

      if (this.booking.equipamentos.length > 0) {
        this.booking.equipamentos.forEach(equip => {
          equip.quantity = 0
        })
      }

      ProcessService.getProcessEquipments(this.booking.processo.id).then(res => {
        let equipmentList = res.data.data
        this.booking.equipamentos = equipmentList.map(x => {
          return {
            id: x.id,
            descricao: x.description,
            description: x.description,
            quantity: x.quantity
          }
        })
        this.isLoading = false
      })
    },
    travelFetchOptions () {
    },
    filterBy (option, label, search) {
      let splited = search.toLocaleLowerCase().split(' ')
      let lowerLabel = label.toLocaleLowerCase()

      return splited.reduce((founded, term) => {
        founded += lowerLabel.indexOf(term) > -1
        return founded
      })
    }
  },
  computed: {
    equipamentsError () {
      return this.submitted && this.booking.equipamentos.length <= 0
    },
    processInformed () {
      return this.booking.processo != null
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
